<template>
  <div style="background-color: #FFF; padding: 20px">
    <div>
      <el-form size="small" :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item label="编码">
          <el-input v-model="queryForm.tagId" placeholder="拓展字段编码" clearable></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="queryForm.tagName" placeholder="拓展字段名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="queryForm.tagType" placeholder="数据类型" clearable>
            <el-option label="文本" value="text"></el-option>
            <el-option label="数值" value="number"></el-option>
            <el-option label="位置" value="location"></el-option>
            <el-option label="枚举" value="enum"></el-option>
            <el-option label="引用" value="table"></el-option>
            <el-option label="图片" value="image"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">查询</el-button>
          <el-button type="success" @click="openAddDialog" icon="el-icon-circle-plus">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      v-loading="loading"
      :data="tableData"
      border
      style="width: 100%">
      <el-table-column
        prop="tagId"
        label="编码"
        width="100">
      </el-table-column>
      <el-table-column
        prop="tagName"
        width="150"
        label="字段含义">
      </el-table-column>
      <el-table-column
        width="150"
        label="显示文本">
        <template scope="{ row }">
          <span style="color: #3472c7; font-weight: 500">{{ row.tagDisplay }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        label="数据类型">
        <template scope="{ row }">
          <span v-if="row.tagType === 'text'">文本</span>
          <span v-if="row.tagType === 'number'">数值</span>
          <span v-if="row.tagType === 'location'">位置</span>
          <span v-if="row.tagType === 'enum'">枚举</span>
          <span v-if="row.tagType === 'table'">引用</span>
          <span v-if="row.tagType === 'image'">图片</span>
        </template>
      </el-table-column>
      <el-table-column
        width="80"
        label="是否展示">
        <template scope="{ row }">
          <el-switch
            @change="changeShowStatus(row)"
            v-model="row.tagShow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        width="80"
        label="字段槽位">
        <template scope="{ row }">
          <el-tag size="small">{{ row.bindPos }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="tagRemark"
        label="备注">
      </el-table-column>
      <el-table-column
        width="160"
        align="center"
        label="操作">
        <template scope="{ row }">
          <el-button size="mini" type="primary" @click="editField(row)">编辑</el-button>
          <el-button disabled size="mini" type="danger">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right; margin-top: 20px">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="10">
      </el-pagination>
    </div>
    <el-dialog
      title="创建字段"
      :visible.sync="addDialog"
      width="500px">
      <el-form size="small" :model="form" :rules="rules" ref="form" label-width="80px" class="demo-form" v-loading="addLoading">
        <el-form-item label="字段编码" prop="tagId">
          <el-input v-model="form.tagId"></el-input>
        </el-form-item>
        <el-form-item label="字段含义" prop="tagName">
          <el-input v-model="form.tagName"></el-input>
        </el-form-item>
        <el-form-item label="显示文本" prop="tagDisplay">
          <el-input v-model="form.tagDisplay"></el-input>
        </el-form-item>
        <el-form-item label="字段类型" prop="tagType">
          <el-select v-model="form.tagType" placeholder="请选择字段类型">
            <el-option label="文本" value="text"></el-option>
            <el-option label="数值" value="number"></el-option>
            <el-option label="位置" value="location"></el-option>
            <el-option label="枚举" value="enum"></el-option>
            <el-option label="引用" value="table"></el-option>
            <el-option label="图片" value="image"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="枚举键值" prop="link" v-if="form.tagType === 'enum'">
          <el-input v-model="form.link"></el-input>
        </el-form-item>
        <el-form-item label="单选引用" prop="link" v-if="form.tagType === 'table'">
          <el-select v-model="form.link" placeholder="请选择关联数据表">
            <el-option v-for="table in tables" :label="table.expandTableName" :value="table.alias" :key="table.alias"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="引用字段" prop="linkField" v-if="form.tagType === 'table'">
          <el-input v-model="form.linkField" placeholder="引用字段"></el-input>
        </el-form-item>
        <el-form-item label="字段槽位" prop="bindPos">
          <el-select v-model="form.bindPos" placeholder="请选择字段槽位">
            <el-option v-for="i in 10" :key="i" :label="i" :value="'tag_' + i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="字段备注" prop="tagRemark">
          <el-input type="textarea" v-model="form.tagRemark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="addDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitForm('form')">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="编辑字段"
      :visible.sync="editDialog"
      width="500px">
      <el-form size="small" :model="formEdit" :rules="rulesEdit" ref="formEdit" label-width="80px" class="demo-form" v-loading="editLoading">
        <el-form-item label="字段编码" prop="tagId">
          <el-input v-model="formEdit.tagId"></el-input>
        </el-form-item>
        <el-form-item label="字段含义" prop="tagName">
          <el-input v-model="formEdit.tagName"></el-input>
        </el-form-item>
        <el-form-item label="显示文本" prop="tagDisplay">
          <el-input v-model="formEdit.tagDisplay"></el-input>
        </el-form-item>
        <el-form-item label="字段类型" prop="tagType">
          <el-select disabled v-model="formEdit.tagType" placeholder="请选择字段类型">
            <el-option label="文本" value="text"></el-option>
            <el-option label="数值" value="number"></el-option>
            <el-option label="位置" value="location"></el-option>
            <el-option label="枚举" value="enum"></el-option>
            <el-option label="引用" value="table"></el-option>
            <el-option label="图片" value="image"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="枚举键值" prop="link" v-if="formEdit.tagType === 'enum'">
          <el-input v-model="formEdit.link"></el-input>
        </el-form-item>
        <el-form-item label="单选引用" prop="link" v-if="formEdit.tagType === 'table'">
          <el-select v-model="formEdit.link" placeholder="请选择关联数据表">
            <el-option v-for="table in tables" :label="table.expandTableName" :value="table.alias" :key="table.alias"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="引用字段" prop="linkField" v-if="formEdit.tagType === 'table'">
          <el-input v-model="formEdit.linkField" placeholder="引用字段"></el-input>
        </el-form-item>
        <el-form-item label="字段槽位" prop="bindPos">
          <el-select disabled v-model="formEdit.bindPos" placeholder="请选择字段槽位">
            <el-option v-for="i in 10" :key="i" :label="i" :value="'tag_' + i"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="字段备注" prop="tagRemark">
          <el-input type="textarea" v-model="formEdit.tagRemark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="editDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitUpdate('formEdit')">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { extendFieldsList, addExtendFields, updateColShow, updateCol } from '@/api/sysTag'
import {expandTablesList} from "@/api/expandTable";

export default {
  name: 'name',
  data () {
    return ({
      tables: [],
      addDialog: false,
      editDialog: false,
      loading: true,
      editLoading: false,
      addLoading: false,
      queryForm: {
        tagId: '',
        tagName: '',
        tagType: ''
      },
      form: {
        tagId: '',
        tagName: '',
        tagDisplay: '',
        tagType: 'text',
        tagRemark: '',
        tagShow: 1,
        bindPos: 'tag_1'
      },
      rules: {
        tagId: [
          { required: true, message: '请输入字段编码', trigger: 'blur' }
        ],
        tagName: [
          { required: true, message: '请输入字段名称', trigger: 'blur' }
        ],
        tagDisplay: [
          { required: true, message: '请输入显示文本', trigger: 'blur' }
        ],
        tagType: [
          { required: true, message: '请选择字段类型', trigger: 'change' }
        ],
        bindPos: [
          { required: true, message: '请选择字段槽位', trigger: 'change' }
        ],
        link: [
          { required: true, message: '请输入键值对信息', trigger: 'change' }
        ],
        linkField: [
          { required: true, message: '请输入字段', trigger: 'change' }
        ]
      },
      formEdit: {
        tagId: '',
        tagName: '',
        tagDisplay: '',
        tagType: 'text',
        tagRemark: '',
        tagShow: 1,
        bindPos: 'tag_1'
      },
      rulesEdit: {
        tagId: [
          { required: true, message: '请输入字段编码', trigger: 'blur' }
        ],
        tagName: [
          { required: true, message: '请输入字段名称', trigger: 'blur' }
        ],
        tagDisplay: [
          { required: true, message: '请输入显示文本', trigger: 'blur' }
        ],
        tagType: [
          { required: true, message: '请选择字段类型', trigger: 'change' }
        ],
        bindPos: [
          { required: true, message: '请选择字段槽位', trigger: 'change' }
        ],
        link: [
          { required: true, message: '请输入键值对信息', trigger: 'change' }
        ],
        linkField: [
          { required: true, message: '请输入字段', trigger: 'change' }
        ]
      },
      tableData: [{}]
    })
  },
  mounted () {
    this.listAllFields()
    this.expandTablesList()
  },
  methods: {
    async expandTablesList () {
      const res = await expandTablesList({})
      this.tables = res.data
    },
    async editField (row) {
      this.editDialog = true
      setTimeout(() => {
        this.resetForm('formEdit')
        this.formEdit = JSON.parse(JSON.stringify(row))
      }, 10)
    },
    async changeShowStatus (row) {
      this.loading = true
      const res = await updateColShow(row)
      this.loading = false
      if (res.code !== 0) {
        this.$message.error(res.msg)
        row.tagShow = !row.tagShow
      }
    },
    async onSubmit () {
      await this.listAllFields()
    },
    async listAllFields () {
      this.loading = true
      const res = await extendFieldsList(this.queryForm)
      this.loading = false
      this.tableData = res.data
    },
    async addExtendFields () {
      this.addLoading = true
      const res = await addExtendFields(this.form)
      this.addLoading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.addDialog = false
        this.listAllFields()
      } else {
        this.$message.error(res.msg)
      }
    },
    async openAddDialog () {
      this.addDialog = true
      this.resetForm('form')
    },
    async submitForm (formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addExtendFields()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async updateField () {
      this.editLoading = true
      const res = await updateCol(this.formEdit)
      this.editLoading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.editDialog = false
        this.listAllFields()
      } else {
        this.$message.error(res.msg)
      }
    },
    async submitUpdate (formName) {
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateField()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
