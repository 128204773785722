import request from '@/utils/request'

export function extendFieldsList (data) {
  return request({
    url: '/sys/extendFields/list',
    method: 'POST',
    data
  })
}
export function addExtendFields (data) {
  return request({
    url: '/sys/extendFields/add',
    method: 'POST',
    data
  })
}
export function getColsSet (data) {
  return request({
    url: '/sys/extendFields/getColsSet',
    method: 'POST',
    data
  })
}
export function addUserColsSet (data) {
  return request({
    url: '/sys/extendFields/addUserColsSet',
    method: 'POST',
    data
  })
}
export function updateUserColsSet (data) {
  return request({
    url: '/sys/extendFields/updateUserColsSet',
    method: 'POST',
    data
  })
}
export function updateColShow (data) {
  return request({
    url: '/sys/extendFields/updateColShow',
    method: 'POST',
    data
  })
}
export function updateCol (data) {
  return request({
    url: '/sys/extendFields/updateCol',
    method: 'POST',
    data
  })
}
