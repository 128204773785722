import request from '@/utils/request'

export function expandTablesList (data) {
  return request({
    url: '/sys/expandTableMain/list',
    method: 'POST',
    data
  })
}
export function addExpandTable (data) {
  return request({
    url: '/sys/expandTableMain/add',
    method: 'POST',
    data
  })
}
export function updateExpandTable (data) {
  return request({
    url: '/sys/expandTableMain/update',
    method: 'POST',
    data
  })
}
